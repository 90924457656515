<template lang="html">
  <div v-if="this.$parent.$parent.status === 'accept'" class="embed-responsive-config embed-responsive-1by2">
    <iframe
      class="embed-responsive-item"
      allow="clipboard-write; encrypted-media; fullscreen"
      allowfullscreen
      src="https://experience-lead.batitrade.com/leflochmetalconcept"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "ConfiguratorToulousaine",
};
</script>
<style lang="css">
@media (min-width: 576px) {
  .embed-ex {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

@media (max-width: 576px) {
  .embed-responsive-config {
    width: 100% !important;
  }
}

.embed-ex {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}
</style>
<style lang="scss">
.embed-responsive-config {
  position: relative;
  display: block;
  border-radius: 10px;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-1by2 {
  &::before {
    padding-top: percentage(1/2);
  }
}
</style>
