<template>
  <div class="myflex-column cover min-pb">
    <ConfiguratorToulousaine />
    <div class="spacer"></div>
    <div class="cover-heading usefont paddup">
      Le configurateur ne s'affiche pas bien chez vous ?
      <p class="lead paddup">
        <a
          href="https://experience-lead.batitrade.com/leflochmetalconcept" target="_blank"
          class="btn btn-secondary"
          > Accéder au configurateur !
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import ConfiguratorToulousaine from "/src/components/ConfiguratorToulousaine.vue"
export default {
  name: 'Configurator',
  components: {
    ConfiguratorToulousaine
  },
  metaInfo: {
      title: 'Configurateur',
      titleTemplate: '%s | LEFLOCH Métal Concept'
    }
};
</script>
<style lang="css">

.myflex-column {
    display: flex !important;
    min-height: fit-content;
    flex-direction: column;
}

@media (max-width: 576px) {
  .myflex-column {
    display: block!important;;
  }
  .informations {
    padding-top: 10px;
  }
}

.paddup {
  padding-top: 5px;
}
.min-pb {
  padding-bottom: min(25px, 5%);
}
</style>
